
// Custom Theming for Angular Material
// For more information: https://htmlelements.com/docs/themebuilder/
@use '@angular/material' as mat;
@import "assets/theme.css";
@import 'smart-webcomponents-angular/source/styles/smart.default.css';
@import 'assets/smart.fm.css';
//@import 'primeng/resources/themes/md-light-indigo/theme.css';
@import 'primeng/resources/primeng.min.css';

// Import core styles
@include mat.core();

// Define custom color palettes
$IM-primary: mat.define-palette(mat.$grey-palette);
$IM-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$IM-warn: mat.define-palette(mat.$red-palette);

// Define theme using custom color palettes
$IM-theme: mat.define-light-theme(
    (
      color: (
        primary: $IM-primary,
        accent: $IM-primary,
        warn: $IM-warn,
      ),
    )
);

// Apply theme to all Angular Material components
@include mat.all-component-themes($IM-theme);

//@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  //--mat-badge-background-color: #ff4c4c;
}
body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  //font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: inherit !important;
}

.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: nowrap;
  align-items: center;
}

.p-inputtext {
  padding: 1px 1px !important;
  font-size: 14px !important;
  border-radius: 3px;
}

.p-dialog-mask {
  border-radius: 10px;
}

//smart-carousel[display-mode="3d"] .smart-carousel-item {
//  width: calc(100vh - 350px) !important;
//  height: calc(100vh - 255px) !important;
//  border-radius: 10px;
//  border: 2px solid #fff !important;
//  box-shadow: 0 0 6px 0 black !important;
//}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled.onlyimageview label {
  color: rgb(255, 0, 0) !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background, .mdc-checkbox
.mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background, .mdc-checkbox
.mdc-checkbox__native-control[data-indeterminate=true][disabled]~.onlyimageview.mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(255, 0, 0, 0.43) !important;
}

smart-carousel[display-mode="3d"].imageselect .smart-carousel-item {
  width: calc(100vh - 350px) !important;
  height: calc(100vh - 255px) !important;
  border-radius: 10px;
  //border: 2px solid #fff !important;
  box-shadow: 0 0 6px 0 black !important;
}

smart-carousel[display-mode="3d"].imageview .smart-carousel-item {
  width: calc(100vh - 220px) !important;
  height: calc(100vh - 145px) !important;
  border-radius: 10px;
  //border: 2px solid #fff !important;
  box-shadow: 0 0 6px 0 black !important;
}

smart-carousel[display-mode="3d"].mobile .smart-carousel-item {
  width: 300px !important;
  height: calc(100vh - 345px) !important;
  border-radius: 10px;
  border: 2px solid #fff !important;
  box-shadow: 0 0 6px 0 black !important;
}

smart-carousel.mobile .smart-carousel-item .smart-carousel-item-content,
smart-carousel .smart-carousel-item .smart-carousel-item-label {
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  top: 5% !important;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0);
  color: white;
}

smart-carousel.mobile .smart-indicators-container {
  top: 40px !important;
  bottom: auto !important;
}

smart-carousel .smart-indicators-container {
  top: 0 !important;
  bottom: auto !important;
}

smart-carousel .smart-indicators-container .smart-indicator {
  width: 4px !important;
  height: 4px !important;
}

.mat-mdc-card-title-group {
  display: flex !important;
  width: 100% !important;
  flex-direction: column-reverse !important;
  align-items: center !important;
}

.smart-carousel.mobile .smart-arrow {
  position: absolute;
  height: 200px !important;
  cursor: pointer;
  opacity: .6;
  display: block;
  top: 50% !important; /* Positions the middle of the arrow at the center of the carousel */
  transform: translateY(-50%) !important;
}

smart-carousel .smart-indicator {
  border-color: white;
  box-shadow: 0 0 1px #000;
  border-radius: 50% !important;
  background-color: white;
}

.smart-badge.smart-badge-success {
  color: #ff0000 !important;
  left: -5px;
  bottom: 20px;
  position: absolute;
  background-color: transparent !important;
  transform: rotate(335deg);
}

.mat-mdc-fab .mat-icon, .mat-mdc-fab .material-icons, .mat-mdc-mini-fab .mat-icon, .mat-mdc-mini-fab .material-icons {
  font-size: 18px !important;
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
}

smart-progress-bar .smart-label {
  width: auto !important;
  color: #a8a8a8 !important;
  font-weight: bold !important;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2rem;
}

.p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
  padding: 0.75rem 0;
}

.p-button {
  padding: 0.3rem 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: transparent;
  color: #64748b;
  height: 30px;
  border: 1px solid;
}

.mat-badge-content {
  background-color: #ff9c9c !important;
}

//.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined,
//.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
//  color: #000000 !important;
//  width: 250px !important;
//  border: 1px solid !important;
//  box-shadow: 0 0 5px black !important;
//}

//.mat-mdc-card-content {
//  display: block;
//  padding: 0 0 !important;
//  padding-top: 16px !important;
//}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 12px; /* Fully rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom scrollbar styles for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #f1f1f1;
}

/* Trying to mimic rounded corners in Firefox */
.scroll-container {
  overflow-y: scroll;
}

.scroll-container * {
  scrollbar-width: thin;
  scrollbar-color: #000000 #f1f1f1;
}

/* For Edge */
.scroll-container {
  -ms-overflow-style: scrollbar;
}

.notification-error {
  color: red !important;
}

.ant-notification-notice {
  border-radius: 15px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  //background-color: rgba(55, 81, 113, 0.05);
  border-radius: 5px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(55, 81, 113, 0.2) !important;
  border-radius: 5px;
}

//.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//  color: rgba(0, 0, 0, 0.85);
//  font-weight: 600;
//  background-color: #d5d5d5;
//}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 600;
  background-color: rgba(55, 81, 113, 0.2) !important;;
}

.blockbuilder-branding {
  display: none !important;
}

.email-editor-wrapper .blockbuilder-branding {
  display: none !important;
}

::ng-deep .blockbuilder-branding {
  display: none !important;
}

.container .blockbuilder-branding {
  display: none !important;
}

.ant-tag {
  margin: 2px 2px !important;
  border-radius: 5px !important;
}

.ant-divider-horizontal {
  margin: 5px 0 !important;
}

.p-button.p-button-icon-only.p-button-rounded {
  height: 0.1rem !important;
}

.p-button.p-button-icon-only {
  width: 1.6rem !important;
}

//.anticon svg {
//  //display: none !important;
//  /* display: inline-block; */
//}

.mat-mdc-fab:not(.mdc-fab--extended), .mat-mdc-mini-fab:not(.mdc-fab--extended) {
  height: 30px !important;
  width: 30px !important;
}

.p-divider.p-divider-horizontal {
  margin: 0.25rem 0;
  padding: 0 0.25rem;
}


.ant-divider {
  border-top: 2px solid rgba(0, 0, 0, 0.06) !important;
}
